<template>
  <div
    :class="clubVersion === 'v2' ? `v2-${theme}` : ''"
    :data-v2-theme="theme"
  >
    <SiteTemplate v-if="clubVersion !== 'v2' && (loadNavbar || loadSlideBar)">  
      <div class="box-notificacoes"></div>
      <router-view ></router-view>
    </SiteTemplate>
    <SiteTemplateV2 v-else-if="loadNavbar">
      <div class="box-notificacoes"></div>
      <router-view ></router-view>
    </SiteTemplateV2> 
    <router-view v-else></router-view>  
  </div>
</template>

<script>
export default {
  name: "app",
  components: {  
    SiteTemplateV2: () => import('@/templates/SiteTemplateV2.vue'),
    SiteTemplate: () => import('@/templates/SiteTemplate.vue'),
  },
  data() {
    return {   
      loadNavbar: false,
      loadSlideBar: false,
      client: {
        width: 0,
      },
    };
  },
  watch: {
    $route() {
      this.loadNavbar = this.$route.meta.showNavbar === false ? false : true;
      this.loadSlideBar = this.$route.meta.showSlideBar === true ? true : false;
    }
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      let theme = "light";
      if (
        this.$store.state.globalSettings.theme !== undefined &&
        this.$store.state.globalSettings.theme !== null &&
        this.$store.state.globalSettings.theme !== "undefined"
      ) {
        theme = this.$store.state.globalSettings.theme;
      }
      return theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion || "v1";
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();   
  },
  mounted() {
    // this.setLanguage();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    setLanguage() {
      let locale = localStorage.getItem("location");
      console.log(locale);
      this.search = "";
      if (
        ["AR", "CO", "CL", "EC", "GT", "MX", "PE", "UY", "ES"].includes(locale)
      ) {
        this.$i18n.locale = "es";
      } else if (["US"].includes(locale)) {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "pt";
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "./assets/scss/main.scss";

@font-face {
  font-family: "Anton";
  src: url("./assets/fonts/Anton.ttf");
}

@font-face {
  font-family: "Architects Daughter";
  src: url("./assets/fonts/ArchitectsDaughter.ttf");
}

@font-face {
  font-family: "Cabin Condensed";
  src: url("./assets/fonts/CabinCondensed.ttf");
}

@font-face {
  font-family: "Caveat";
  src: url("./assets/fonts/Caveat.ttf");
}

@font-face {
  font-family: "Dancing Script";
  src: url("./assets/fonts/DancingScript.ttf");
}

@font-face {
  font-family: "Gloria Hallelujah";
  src: url("./assets/fonts/GloriaHallelujah.ttf");
}

@font-face {
  font-family: "Great Vibes";
  src: url("./assets/fonts/GreatVibes.ttf");
}

@font-face {
  font-family: "Lobster";
  src: url("./assets/fonts/Lobster.ttf");
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("./assets/fonts/NotoSansJP.otf");
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSansCondensed.ttf");
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald.ttf");
}

@font-face {
  font-family: "Pacifico";
  src: url("./assets/fonts/Pacifico.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto.ttf");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono.ttf");
}

* {
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  display: block;
}

.v2-dark {
  background: #16181a;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    .crisp-client {
      padding-bottom: 80px !important;
    }
  }
}

.v2-light {
  background: var(--background);
  overflow: hidden;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: #d6d6d6 !important;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: var(--maincolor) !important;
  }
}

@media screen and (max-width: 768px) {
  .vue-swatches__wrapper {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

.w100 {
  width: 100%;
}

.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}

.carregando {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999 !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ring {
  width: 80px;
  height: 80px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 4px 0 rgb(219, 219, 219);
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
}

.loader-txt {
  width: 80px;
  height: 80px;
  color: #fff;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 5px #262626;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
}

.modoFoco {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.644);
}

.loader-img {
  width: 50px;
  height: 50px;
  justify-content: center;
  img {
    position: relative;
    top: 65px;
    left: 15px;
  }
}

[data-anima="mobile"] {
  animation: op 0.5s forwards;
}
@keyframes op {
  from {
    opacity: 0;
    transform: rotate(180deg);
  }
  to {
    opacity: 1;
    transform: rotate(0);
  }
}
[data-anima="top"] {
  animation: showTop 0.5s forwards;
}
@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}
@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="left"] {
  animation: showLeft 0.5s forwards;
}

input,
select,
textarea {
  background: transparent !important;
  color: var(--fontcolor) !important;
  border-color: var(--bordercolor) !important;
}

@keyframes showLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="right"] {
  animation: showRight 0.5s forwards;
}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="sidebar"] {
  animation: showSidebar 0.3s forwards;
}
@keyframes showSidebar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
#modal-show-pdf {
  .modal-content {
    height: 94vh;
  }
  .modal-header {
    z-index: 999;
    border: none !important;
    padding: 0 !important;
    position: absolute !important;
    right: 35px !important;
  }
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 5px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("./assets/icons/notify_default.svg") no-repeat center center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}
// sucesso
.b-toast-success .toast-header strong {
  color: #00e4a0;
}
.b-toast-success .toast-header strong::before {
  background: url("./assets/icons/notify_success.svg") no-repeat center;
}
.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: #00e4a0 !important;
  color: #00e4a0 !important;
}
.b-toast-success .toast .toast-header {
  color: #00e4a0 !important;
  background-color: #f2fefa !important;
  border: none !important;
}
.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}

// info
.b-toast-info .toast-header strong {
  color: #2133d2;
}
.b-toast-info .toast-header strong::before {
  background: url("./assets/icons/notify_info.svg") no-repeat center;
}
.b-toast-info .toast {
  background-color: #f4f5fd !important;
  border-color: #2133d2 !important;
  color: #2133d2 !important;
}
.b-toast-info .toast .toast-header {
  color: #2133d2 !important;
  background-color: #f4f5fd !important;
  border: none !important;
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f4f5fd !important;
}

// danger
.b-toast-danger .toast-header strong {
  color: #ff0c37;
}
.b-toast-danger .toast-header strong::before {
  background: url("./assets/icons/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: #ff0c37 !important;
  color: #ff0c37 !important;
}
.b-toast-danger .toast .toast-header {
  color: #ff0c37 !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// warning
.b-toast-warning .toast-header strong {
  color: #ffcb37;
}
.b-toast-warning .toast-header strong::before {
  background: url("./assets/icons/notify_warning.svg") no-repeat center;
}
.b-toast-warning .toast {
  background-color: #fffcf5 !important;
  border-color: #ffcb37 !important;
  color: #ffcb37 !important;
}
.b-toast-warning .toast .toast-header {
  color: #ffcb37 !important;
  background-color: #fffcf5 !important;
  border: none !important;
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fffcf5 !important;
}

// default
.toast-header strong {
  color: #333;
}
.toast-header strong::before {
  background: url("./assets/icons/notify_default.svg") no-repeat center;
}
.toast {
  background-color: #f6f6f7 !important;
  border-color: #333 !important;
  color: #333 !important;
}
.toast .toast-header {
  color: #333 !important;
  background-color: #f6f6f7 !important;
  border: none !important;
}
.b-toast-solid .toast {
  background-color: #f6f6f7 !important;
}

.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.github-markdown-body table{
  color: black !important;
}

.descricao .github-markdown-body {
    background: var(--backgroundcolor);
}

.descricao-mobile .github-markdown-body {
    background: var(--backgroundcolor);
}


.github-markdown-body{
  color: var(--fontcolor);
}

#editor-markdown {
  textarea, textarea:hover{
    border: none;
    height: -webkit-fill-available;
  }

  .v-md-editor__toolbar, .scrollbar__wrap{
    background: var(--background);
  }

  .v-md-editor-preview{
    color: var(--fontcolor);
  }

  .v-md-editor__toolbar-item{
    color: var(--fontcolor);
    &:hover{
      background: var(--maincolor);
    }
  }

  .v-md-editor__toolbar-item--active{
    background: var(--maincolor);
    color: var(--fontcolor);
  }

}
@media screen and (min-width: 768px) and (max-width: 1440){
  #modal-plans .modal-lg, .modal-xl {
      max-width: 1100px !important;
  }
}
@media (min-width: 992px){
  #modal-plans  .modal-lg, .modal-xl {
      max-width: 1100px !important;
  }
}
@media (min-width: 576px){
  #modal-plans  .modal-dialog {
      max-width: 1100px !important;
      margin: 1.75rem auto;
  }
}


.v-md-textarea-editor, .v-md-textarea-editor textarea {
  min-height: 100vh !important;
}

.v-md-textarea-editor,  .v-md-textarea-editor textarea:hover {
  min-height: 100vh !important;
}


</style>
